import img1 from "../images/electronics/pgt-1310b.png";
import img2 from "../images/electronics/pgt-1202.png";
import img3  from "../images/electronics/pgt-1716.png";
import img4  from "../images/electronics/pgt-1218.png";
import img5  from "../images/electronics/sw-056.png";
import img6  from "../images/electronics/sw-029.png";
import img7  from "../images/tapes/image-002.jpg";
import img8  from "../images/tapes/image-001.jpg";
import img9  from "../images/tapes/image-007.jpg";
import img10  from "../images/tapes/image-006.jpg";
import img11  from "../images/tapes/image-008.jpg";
import img12  from "../images/tapes/image-038.jpg";
import img13  from "../images/tapes/image-039.jpg";
import img14  from "../images/tapes/image-104.jpg";
import img15  from "../images/tapes/image-162.jpg";
import img16  from "../images/tapes/image-088.jpg";
import ldpe from "../images/products/ldpe.webp";
import hdpe from "../images/products/hdpe.webp";
import lldpe from "../images/products/lldpe.webp";
import ironOxide from "../images/products/iron-oxide.png";
import pvcResin from "../images/products/pvc-resin.webp";
import titaniumOxide from "../images/products/titanium.jpeg";
import doorSkin from "../images/products/door-skin.png";
import filmFaced from "../images/products/film-faced.png";
import okoumePlywood from "../images/products/okoumePlywood.png";
import okoumeVaneer from "../images/products/okoumeVeneer.png";
import pinePlywood from "../images/products/pinePlywood.png";



export const electronics = [
  {
    id: "1",
    name: "Snack Maker",
    model: "PGT-1310B",
    makes: "6 brownies",
    imageSrc: img1,
    specifications: ["110-120V 60Hz 900W", "220-240V 50Hz 900W"],
    packingDetails: {
      pcsPerCtn: null,
      giftBoxSize: null,
      cartonSize: null,
      gw: null,
      nw: null,
      pcs20GP: null,
      pcs40GP: null,
      pcs40HQ: null,
    },
  },
  {
    id: "2",
    name: "Sandwich Maker",
    description: "",
    description: "",
    model: "SW-029",
    makes: null,
    imageSrc: img6,
    specifications: ["110-120V 60Hz 700W", "220-240V 50Hz 750W"],
    packingDetails: {
      pcsPerCtn: "6pcs/Ctn",
      giftBoxSize: "26 x 10.3 x 24.8 Cm",
      cartonSize: "32.5 x 27 x 51.4 Cm",
      gw: "8.3 KGS",
      nw: "7.5 KGS",
      pcs20GP: "3690pcs/20GP",
      pcs40GP: "7680pcs/40GP",
      pcs40HQ: "9000pcs/40HQ",
    },
  },
  {
    id: "3",
    name: "Sandwich Maker",
    description: "",
    model: "SW-056/SW-056A",
    makes: null,
    imageSrc: img5,
    specifications: ["110-120V 60Hz 700W", "220-240V 50Hz 700W"],
    packingDetails: {
      pcsPerCtn: "6pcs/Ctn",
      giftBoxSize: "24 x 11.2 x 23.5 Cm",
      cartonSize: "35 x 25.5 x 49 Cm",
      gw: "9.2 KGS",
      nw: "8.3 KGS",
      pcs20GP: "3966pcs/20GP",
      pcs40GP: "8100pcs/40GP",
      pcs40HQ: "9300pcs/40HQ",
    },
  },
  {
    id: "4",
    name: "Omelet Maker",
    description: "",
    model: "PGT-1716",
    makes: null,
    imageSrc: img3,
    specifications: ["120V 60Hz 800W"],
    packingDetails: {
      pcsPerCtn: "8pcs/Ctn",
      giftBoxSize: "36.7 x 10.5 x 14.5 Cm",
      cartonSize: "43.5 x 38.2 x 31 Cm",
      gw: "9.0 KGS",
      nw: "7.2 KGS",
      pcs20GP: "4320pcs/20GP",
      pcs40GP: "9000pcs/40GP",
      pcs40HQ: "10520pcs/40HQ",
    },
  },
  {
    id: "5",
    name: "Griddle Grill",
    description: "",
    model: "SW-088/PGT-1202",
    makes: null,
    imageSrc: img2,
    specifications: ["110-120V 60Hz 600W", "220-240V 50Hz 700W"],
    packingDetails: {
      pcsPerCtn: "6pcs/Ctn",
      giftBoxSize: "26.5 x 13 x 23.8 Cm",
      cartonSize: "54 x 41 x 25.8 Cm",
      gw: "11.2 KGS",
      nw: "10.2 KGS",
      pcs20GP: "2946pcs/20GP",
      pcs40GP: "5892pcs/40GP",
      pcs40HQ: "7050pcs/40HQ",
    },
  },
  {
    id: "6",
    name: "Griddle Grill",
    description: "",
    model: "PGT-1218",
    makes: null,
    imageSrc: img4,
    specifications: ["110-120V 60Hz 700W", "220-240V 50Hz 700W"],
    packingDetails: {
      pcsPerCtn: "4pcs/Ctn",
      giftBoxSize: "26 x 16.5 x 31.5 Cm",
      cartonSize: "67.5 x 27.5 x 33 Cm",
      gw: "10.2 KGS",
      nw: "9.2 KGS",
      pcs20GP: "1800pcs/20GP",
      pcs40GP: "3600pcs/40GP",
      pcs40HQ: "3900pcs/40HQ",
    },
  },
];

export const tapeProducts = [
  {
    id: 1,
    name: "Matt Gaffer Cloth Duct Tape",
    code: "GAF30",
    glue: "hot melt",
    thickness: "300±10um",
    tack: "20⬆#",
    peelAdhesive: "20N/in⬆",
    tensileStrength: "150N/in⬆",
    mainUsage: "Suitable for audio-visual equipment, TV, film industry, etc.",
    description: "Durable, high-tack tape for TV, film, and audio-visual equipment.",
    imageSrc: img7,
  },
  {
    id: 2,
    name: "Printing Cloth Duct Tape",
    code: "CTP",
    glue: "hot melt",
    thickness: "210um",
    tack: "8⬆#",
    peelAdhesive: "18N/in⬆",
    tensileStrength: "65N/in⬆",
    mainUsage: "For DIY, decoration, gift wrapping, book protection, etc.",
    description: "Ideal for DIY projects, gift wrapping, and creative uses.",
    imageSrc: img8,
  },
  {
    id: 3,
    name: "Fluorescent Gaffer Tape",
    code: "FGT",
    glue: "natural rubber",
    thickness: "300±10um",
    tack: "22⬆#",
    peelAdhesive: "18N/in⬆",
    tensileStrength: "14N/in⬆",
    mainUsage: "Used in stage lighting and audio-visual environments.",
    description: "High-visibility gaffer tape used in stage and lighting setups.",
    imageSrc: img9,
  },
  {
    id: 4,
    name: "Camouflage Cloth Tape",
    code: "CCT",
    glue: "hot melt",
    thickness: "280±um",
    tack: "8⬆#",
    peelAdhesive: "14.7N/in⬆",
    tensileStrength: "343N/in⬆",
    mainUsage: "Suitable for outdoor protection and anti-slip applications.",
    description: "Camouflage tape for outdoor gear and anti-slip uses.",
    imageSrc: img10,
  },
  {
    id: 5,
    name: "Double Sided Carpet Tape",
    code: "DESCT160",
    glue: "hot melt",
    thickness: "200um",
    tack: "20⬆#",
    peelAdhesive: "20⬆#",
    tensileStrength: "80N/in⬆",
    mainUsage: "Used for carpet stitching and pipe wrapping.",
    description: "Strong double-sided tape for carpets and pipe wrapping.",
    imageSrc: img11,
  },
  {
    id: 6,
    name: "General Purpose Masking Tape",
    code: "MT14",
    glue: "rubber",
    thickness: "140-150um",
    tack: "8⬆#",
    peelAdhesive: null,  // Default null
    tensileStrength: "50N/in⬆",
    mainUsage: "Suitable for decoration, electronics, and footwear industries.",
    description: "Versatile masking tape for various industries.",
    imageSrc: img12,
  },
  {
    id: 7,
    name: "Self-Adhesive Kraft Paper Tape",
    code: "SKT1",
    glue: "hot melt",
    thickness: "120um",
    tack: "3⬆#",
    peelAdhesive: "8N/25MM",
    tensileStrength: "90N/in⬆",
    mainUsage: "Used for carton sealing and temporary packaging.",
    description: "Strong kraft paper tape for packaging and sealing.",
    imageSrc: img13,
  },
  {
    id: 8,
    name: "Mono-Directional Filament Tap",
    code: "FTA13",
    glue: "hot melt",
    thickness: "0.13mm",
    tack: "5⬆#",
    peelAdhesive: "18N/25MM",
    tensileStrength: "280N/in⬆",
    mainUsage: "Used for packaging heavy objects and cable bundling.",
    description: "Filament tape for heavy-duty packaging and bundling.",
    imageSrc: img14,
  },
  {
    id: 9,
    name: "Reflective Tape",
    code: "RTH02",
    glue: "acrylic",
    thickness: "0.11mm",
    tack: "10⬆#",
    peelAdhesive: null,  // Default null
    tensileStrength: null,  // Default null
    mainUsage: "Used for roads, cars, and various warning applications.",
    description: "Reflective tape for road safety and warning signs.",
    imageSrc: img15,
  },
  {
    id: 10,
    name: "Double Sided PET Tape",
    code: "DSTPP-1",
    glue: "hot melt",
    thickness: "90um",
    tack: "8⬆#",
    peelAdhesive: "10N/in⬆",
    tensileStrength: null,  // Default null
    mainUsage: "Used in leather, electronics, and automobile industries.",
    description: "Double-sided PET tape for leather, electronics, and auto uses.",
    imageSrc: img16,
  }
];

export const ldpeProducts = [
  {
    id: 1,
    name: "2426H Film LDPE for Film and Bag",
    mfr: "2.0 g/10 min",
    materialType: "LDPE 2426H (Film Grade)",
    applications: [
      "Packaging films",
      "Bags and pouches for food, pharmaceuticals, consumer goods",
      "Daily necessities, industrial, and agricultural films"
    ],
    materialProperties: [
      "High clarity",
      "Low melt index",
      "Excellent drawdown properties",
      "Toughness and puncture resistance",
      "Good heat sealing properties"
    ],
    manufacturingProcess: "Produced through high-pressure polymerization, extruded into pellets or granules",
    technicalSpecifications: {
      mfi: "~2.0 g/10 min",
      other: ["Density", "Tensile strength", "Elongation at break", "Impact strength"]
    },
    safetyAndEnvironmentalConsiderations: [
      "FDA approved for food packaging",
      "Proper handling and disposal required to minimize environmental impact"
    ],
    disclaimer: null,
    storageAndHandling: null,
    imageSrc: ldpe,
    description: "LDPE 2426H is commonly used for making packaging films and bags with excellent toughness and clarity."
  },
  {
    id: 2,
    name: "2426K Film LDPE for Plastic Bags",
    mfr: "4.0 g/10 min",
    materialType: "LDPE 2426K (Film Grade)",
    applications: [
      "Food packaging (wrapping films for cheese, meat, etc.)",
      "Agricultural films (greenhouse films, silage films, mulch films)",
      "Industrial films (shrink films, stretch films, protective films)",
      "Consumer products (shopping bags, garbage bags, disposable gloves)"
    ],
    materialProperties: [
      "Good optical properties (clarity and gloss)",
      "Good processability (blown film extrusion or cast film extrusion)",
      "Good mechanical properties (impact resistance, tear strength)",
      "Good sealability (easily heat-sealed)"
    ],
    manufacturingProcess: "Blown film extrusion or cast film extrusion",
    technicalSpecifications: {
      mfi: null,
      other: ["Good clarity and gloss", "Good impact resistance and tear strength", "Good heat-sealing properties"]
    },
    safetyAndEnvironmentalConsiderations: null,
    disclaimer: null,
    storageAndHandling: null,
    imageSrc: ldpe,
    description: "LDPE 2426K is used for films with excellent mechanical strength and sealability, ideal for food and agricultural applications."
  },
  {
    id: 3,
    name: "2023JN Film LDPE for Shrink Film",
    mfr: "2.0 g/10 min",
    materialType: "SABIC LDPE 2023JN (Film Grade)",
    applications: [
      "General-purpose packaging films",
      "Medical, industrial, and agricultural films",
      "Shrink films, lamination films, produce bags, textile packaging",
      "Soft goods packaging, general-purpose bags, t-shirt carrier bags"
    ],
    materialProperties: [
      "High tensile strength and elongation at break",
      "Good optical properties (high gloss, good transparency)",
      "Excellent sealing properties (low sealing temperature, high hot tack strength)",
      "Contains slip and antiblock additives"
    ],
    manufacturingProcess: null,
    technicalSpecifications: {
      mfi: null,
      other: ["High gloss", "Transparency", "Low sealing temperature", "High hot tack strength"]
    },
    safetyAndEnvironmentalConsiderations: null,
    disclaimer: "Not for use in pharmaceutical/medical applications",
    storageAndHandling: {
      storageConditions: "Store away from sunlight/heat",
      processWithin: "6 months of delivery",
      idealStorage: "Dry, <50°C"
    },
    imageSrc: ldpe,
    description: "SABIC LDPE 2023JN is ideal for shrink films and packaging with excellent sealability and mechanical properties."
  },
  {
    id: 4,
    name: "Q281 Film LDPE for Engineering Plastics",
    mfr: "2.8 g/10 min",
    materialType: "LDPE Q281 (Film Grade)",
    applications: [
      "Film applications requiring puncture resistance",
      "Clear packaging films, food packaging films, stretch films"
    ],
    materialProperties: [
      "Toughness, flexibility, and clarity",
      "High impact strength",
      "Excellent flexibility and stretchability",
      "Good optical properties (high gloss, transparency)",
      "Good printability for logos and designs on packaging films"
    ],
    manufacturingProcess: "Blown film extrusion or cast film extrusion",
    technicalSpecifications: {
      mfi: null,
      other: ["High gloss", "Transparency", "High impact strength", "Good stretchability"]
    },
    safetyAndEnvironmentalConsiderations: null,
    disclaimer: null,
    storageAndHandling: null,
    imageSrc: ldpe,
    description: "LDPE Q281 is known for its toughness, flexibility, and puncture resistance, perfect for stretch and packaging films."
  }
];

export const hdpeProducts = [
  {
    id: 1,
    productName: "HDPE Plastic Film",
    mfr: "Manufacturer A",
    materialType: "High-Density Polyethylene (HDPE)",
    applications: ["Packaging", "Agriculture", "Construction"],
    properties: {
      density: "0.95 g/cm³",
      tensileStrength: "30 MPa",
      elongationAtBreak: "600%",
    },
    technicalSpecifications: {
      mfi: "2.5 g/10 min",
      other: ["UV Resistant", "Waterproof"],
    },
    safetyAndEnvironmentalConsiderations: [
      "Non-toxic",
      "Recyclable",
      "Low environmental impact",
    ],
    storageAndHandling: {
      storageConditions: "Store in a cool, dry place.",
      processWithin: "Use within 12 months of manufacture.",
      idealStorage: "Avoid direct sunlight and extreme temperatures.",
    },
    imageSrc: hdpe,
  },
  {
    id: 2,
    productName: "HDPE Pipes",
    mfr: "Manufacturer B",
    materialType: "High-Density Polyethylene (HDPE)",
    applications: ["Water Supply", "Sewage Systems"],
    properties: {
      density: "0.94 g/cm³",
      tensileStrength: "24 MPa",
      impactResistance: "Excellent",
    },
    technicalSpecifications: {
      mfi: "1.8 g/10 min",
      other: ["Corrosion Resistant", "Flexible"],
    },
    safetyAndEnvironmentalConsiderations: [
      "Non-toxic",
      "Can be recycled",
      "Minimal ecological footprint",
    ],
    storageAndHandling: {
      storageConditions: "Keep away from moisture.",
      processWithin: "Best used within 6 months.",
      idealStorage: "Store horizontally on a flat surface.",
    },
    imageSrc: hdpe,
  },
  {
    id: 3,
    productName: "Pipe Grade HDPE P6006",
    mfr: "SABIC",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Supply Pipes",
      "Gas Distribution Pipes",
      "Industrial Pipes",
      "Mining Pipes",
      "Irrigation Pipes",
    ],
    properties: {
      impactStrength: "Excellent",
      stiffness: "High",
      chemicalResistance: "Good",
      temperatureResistance: "High",
      processability: "Good",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: hdpe, // Replace with actual image path
  },
  {
    id: 4,
    productName: "Pipe Grade HDPE PN049",
    mfr: "SINOPEC SABIC",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Supply",
      "Mining",
      "Chemical Processing",
    ],
    properties: {
      strength: "High",
      chemicalResistance: "Highly resistant",
      flexibility: "High",
      longevity: "Durable",
      costEffectiveness: "Cost-effective",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: hdpe, // Replace with actual image path
  },
  {
    id: 5,
    productName: "Pipe Grade HDPE 23050",
    mfr: "Unknown",
    materialType: "Pipe grade HDPE",
    applications: [
      "Sewage Systems",
      "Chemical Industry",
      "Construction Industry",
      "Agricultural Sector",
    ],
    properties: {
      density: "High",
      chemicalResistance: "Good",
      impactStrength: "Good",
      frictionCoefficient: "Low",
      uvResistance: "Excellent",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: hdpe, // Replace with actual image path
  },
  {
    id: 6,
    productName: "Pipe Grade HDPE YGH041T",
    mfr: "Unknown",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Supply",
      "Drainage",
      "Sewage Systems",
      "Gas Distribution",
      "Chemical Transportation",
      "Mining",
      "Agriculture",
      "Trenchless Installations",
    ],
    properties: {
      abrasionResistance: "Excellent",
      chemicalResistance: "High",
      tensileStrength: "High",
      flexibility: "Good",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: hdpe, // Replace with actual image path
  },
  {
    id: 7,
    productName: "Pipe Grade HDPE HE3488LSW",
    mfr: "BOROUGE",
    materialType: "Pipe grade HDPE",
    applications: [
      "Water Distribution",
      "Gas Distribution",
      "Industrial Piping",
      "Sewerage and Drainage",
    ],
    properties: {
      stressCrackResistance: "Excellent",
      toughness: "Good",
      flowRate: "High",
      hydrostaticStrength: "Excellent",
      environmentalStressCrackResistance: "Good",
    },
    technicalSpecifications: {},
    safetyAndEnvironmentalConsiderations: [],
    storageAndHandling: {},
    imageSrc: hdpe, // Replace with actual image path
  },
  // Add more products as needed
];

export const lldpeProducts = [
  {
    id: 1,
    name: "LLDPE 7042",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility, ideal for flexible films.",
      tensileStrength: "High tensile strength, withstands stress without breaking.",
      punctureResistance: "Excellent puncture resistance for packaging applications.",
      heatSealing: "Good heat sealing properties for easy packaging.",
      density: "Low density, lightweight material.",
      clarity: "Good clarity for transparency in applications.",
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Flexible packaging films (bags, pouches, shrink films)",
      "Agricultural films (greenhouse films, mulch films, silage films)",
      "Construction films (vapor barriers, moisture barriers, protective films)",
      "Industrial applications (liners for ponds, landfills, protective covering)",
    ],
    packaging: "Not specified",
    imageSrc: lldpe, // Add the image URL here
  },
  {
    id: 2,
    name: "SABIC 222WT",
    manufacturer: "Saudi Basic Industry Corporation",
    properties: {
      flexibility: null,
      tensileStrength: null,
      punctureResistance: null,
      heatSealing: null,
      density: null,
      clarity: null,
      slip: "High slip",
      mechanicalProperties: "Good mechanical properties",
      stiffness: "High stiffness",
      sealing: "Good sealing properties",
      type: "Ethylene-butene copolymer",
      mfr: "MFR is 1.9 g/10 min (190°C & 2.16 kg)",
    },
    applications: [
      "Cloth bags, carrier bags",
      "Thin layer coatings",
      "Refuse bags",
      "Meat packaging and other food packaging or lamination films",
      "General purpose consumer packaging",
      "Agricultural films (greenhouse films, silage bags, mulch films)",
    ],
    packaging: "Packed in 25kg bag",
    imageSrc: lldpe, // Add the image URL here
  },
  {
    id: 3,
    name: "LLDPE 7047",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility",
      tensileStrength: "High tensile strength",
      punctureResistance: "Puncture resistant",
      heatSealing: null,
      density: null,
      clarity: null,
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Packaging products (bags, films)",
      "Construction films (water barriers, geomembranes)",
      "Agricultural films (greenhouse films, silage bags, mulch films)",
    ],
    packaging: "Not specified",
    imageSrc: lldpe, // Add the image URL here
  },
  {
    id: 4,
    name: "LLDPE 9047",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility for film production",
      tensileStrength: null,
      punctureResistance: null,
      heatSealing: null,
      density: null,
      clarity: null,
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Packaging industry (films and bags)",
      "Construction industry (water barriers, geomembranes)",
      "Agriculture industry (greenhouse films, mulch films, silage bags)",
    ],
    packaging: "Not specified",
    imageSrc: lldpe, // Add the image URL here
  },
  {
    id: 5,
    name: "LLDPE F181",
    manufacturer: "Unknown",
    properties: {
      flexibility: "Good flexibility",
      tensileStrength: null,
      punctureResistance: "High puncture resistance",
      heatSealing: null,
      density: null,
      clarity: null,
      slip: null,
      mechanicalProperties: null,
      stiffness: null,
      sealing: null,
      type: null,
      mfr: null,
    },
    applications: [
      "Packaging industry (films and bags)",
      "Construction industry (geomembranes, construction films)",
      "Agriculture industry (greenhouse films, silage bags, mulch films)",
    ],
    packaging: "Not specified",
    imageSrc: lldpe, // Add the image URL here
  },
];

export const pvcProducts = [
  {
    id: 1,
    type: "PVC SG5",
    description: "PVC SG5 exhibits good chemical stability, corrosion and water resistance, used in transparent name boards, pipes, PVC windows.",
    properties: {
      chemicalResistance: true,
      electricalInsulation: true,
      lowWaterAbsorption: true,
      thermalStability: null,
      weatherability: null,
      goodProcessingProperties: null,
      excellentElectricalProperties: null,
      goodFlameRetardantProperties: null,
    },
    applications: {
      construction: [
        "Pipes",
        "Profiles",
        "Windows",
        "Doors"
      ],
      automotive: [
        "Hoses",
        "Cables",
        "Seals"
      ],
      medical: [
        "Blood bags",
        "Tubing",
        "Medical devices"
      ],
      electrical: [
        "Insulation for wires and cables"
      ],
      consumerGoods: [
        "Toys",
        "Footwear",
        "Household items"
      ]
    },
    imageSrc: pvcResin,
    conclusion: "PVC SG5 is a versatile material with a wide range of applications."
  },
  {
    id: 2,
    type: "PVC SG3",
    description: "PVC SG3 is a chemical that is used in the production of insulation material, daily use plastics, industry packaging.",
    properties: {
      highChemicalResistance: true,
      weatherability: true,
      thermalStability: true,
      chemicalResistance: null,
      electricalInsulation: null,
      lowWaterAbsorption: null,
      goodProcessingProperties: null,
      excellentElectricalProperties: null,
      goodFlameRetardantProperties: null,
    },
    applications: {
      pipesAndFittings: [
        "Water supply",
        "Drainage",
        "Irrigation"
      ],
      electricalApplications: [
        "Electrical insulation",
        "Wiring"
      ],
      medicalApplications: [
        "Tubing",
        "Bags for blood and IV solutions"
      ],
      automotiveApplications: [
        "Door panels",
        "Dashboards",
        "Seat covers"
      ]
    },
    imageSrc: pvcResin,
    conclusion: "PVC SG3 is a versatile and widely used plastic resin."
  },
  {
    id: 3,
    type: "PVC SG8",
    description: "PVC SG8 is a homogeneous white powder, without taste and smell, used for bottles, sheets, calendaring, rigid injection and moulding pipes.",
    properties: {
      goodProcessingProperties: true,
      excellentElectricalProperties: true,
      goodFlameRetardantProperties: true,
      chemicalResistance: null,
      electricalInsulation: null,
      lowWaterAbsorption: null,
      thermalStability: null,
      highChemicalResistance: null,
      weatherability: null,
    },
    applications: {
      construction: [
        "Pipes",
        "Fittings",
        "Profiles for windows and doors"
      ],
      packaging: [
        "Blister packs",
        "Clamshells"
      ],
      automotive: [
        "Dashboards",
        "Door panels",
        "Weatherstripping"
      ]
    },
    imageSrc: pvcResin,
    drawbacks: "PVC is a non-biodegradable material that can release toxic chemicals during production and disposal.",
  },
  {
    id: 4,
    type: "PVC S1000",
    description: "PVC S1000 is a PVC resin the thermoplastic high molecular polymer, for automobile interiors, advertising light box, shoe soles, PVC pipes, PVC profiles.",
    properties: {
      highMechanicalStrength: true,
      goodChemicalResistance: true,
      easyToProcess: true,
      chemicalResistance: null,
      electricalInsulation: null,
      lowWaterAbsorption: null,
      thermalStability: null,
      weatherability: null,
      goodProcessingProperties: null,
      excellentElectricalProperties: null,
      goodFlameRetardantProperties: null,
    },
    applications: {
      buildingAndConstruction: [
        "Pipes",
        "Fittings",
        "Profiles",
        "Roofing sheets",
        "Flooring tiles",
        "Window frames"
      ],
      electricalAndElectronics: [
        "Cables",
        "Wires",
        "Insulators"
      ],
      automotive: [
        "Door panels",
        "Dashboard",
        "Upholstery"
      ],
      packaging: [
        "Bottles",
        "Containers",
        "Blister packs"
      ]
    },
    imageSrc: pvcResin,
    conclusion: "PVC S1000 is a high molecular weight PVC resin with excellent properties for various applications."
  }
];